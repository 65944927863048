import type { Dayjs } from 'dayjs'

export const useIsBeforeDate = (
  date: string | number | Date | Dayjs | null | undefined,
  num: number,
  unit: 'day' | 'month' | 'year',
) => {
  if (!date) return false
  const dayjs = useDayjs()
  const _date = dayjs(date)
  const futureDate = dayjs().add(num, unit)
  return _date.isBefore(futureDate)
}
